<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <base-material-card :title="$route.name" icon="mdi-apple-finder">
          <v-sheet>
            <v-toolbar-items class="d-flex align-center">
              <v-spacer />
              <v-btn color="green" elevation="2" @click="handleDialog">
                <v-icon left>mdi-plus</v-icon>
                {{ $vuetify.lang.t("$vuetify.add") }}
              </v-btn>
            </v-toolbar-items>
          </v-sheet>

          <TableCRUD
            :headers="headers"
            :items="datas"
            @edititem="handleEdit"
            @deleteitem="handleDelete"
          />

          <PaymentTermCRUD
            :dialog.sync="dialog"
            :myObj="obj"
            @handleData="handleEditData"
          />

          <DeleteDialog
            :dialogDelete.sync="deleteDialog"
            :deleteObj="obj"
            @handleDelete="handleDeleteConfirm"
          />
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { parmentList, parmentUpsert, delParment } from "@/api/financial";

const newObj = () => {
  return {
    gen_time: "",
    id: "",
    name: "",
    note: "",
  };
};

export default {
  name: "PaymentTermMgr",
  components: {
    TableCRUD: () => import("@/components/tableCRUD"),
    PaymentTermCRUD: () => import("@/components/financial/paymentTermCRUD"),
    DeleteDialog: () => import("@/components/deleteDialog"),
  },
  data() {
    return {
      dialog: false,
      deleteDialog: false,
      headers: [
        { text: this.$vuetify.lang.t("$vuetify.userName"), value: "name" },
        { text: this.$vuetify.lang.t("$vuetify.note"), value: "note" },
        {
          text: this.$vuetify.lang.t("$vuetify.ChangeTime"),
          value: "gen_time",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "actions",
          sortable: false,
          align: "center",
          width: "80",
        },
      ],
      obj: newObj(),
      datas: [],
    };
  },
  methods: {
    getData() {
      parmentList()
        .then((data) => {
          this.datas = [...data.items];
        })
        .catch((err) => console.log("Payment Term Error", err));
    },
    handleDialog() {
      this.obj = newObj();
      this.dialog = true;
    },
    handleEdit(obj) {
      if (this.permissionCheck("modify")) {
        this.obj = obj;
        this.dialog = true;
      }
    },
    handleEditData(obj) {
      this.dialog = false;

      parmentUpsert(obj)
        .then(() => {
          this.getData();
        })
        .catch((err) => {
          console.log("Payment Term Upsert Error", err);
        });
    },
    handleDelete(obj) {
      this.obj = obj;
      this.deleteDialog = true;
    },
    handleDeleteConfirm(obj) {
      this.deleteDialog = false;

      delParment(obj.id)
        .then(() => {
          this.getData();
        })
        .catch((err) => {
          console.log("Payment Term Delete Error", err);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style></style>
